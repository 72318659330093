<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
          <div class="grid-content bg-purple">
            <div style="display:inline-block" class="title_h mr-3">
              <el-input v-model="form.name" size="small" :placeholder="$t('message.name_template')"></el-input>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button size="medium" type="success" @click="print">{{ $t('message.print') }}</el-button>
              <el-button size="medium" :loading="save_loading" :disabled="save_loading ? true : false" type="success"
                @click="saveData">{{ $t('message.save_and_close') }}</el-button>
              <el-button @click="resetForm('form')">{{ $t('message.close') }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="HEADFOOT">
      <div class="myeditor">
        <!-- <ckeditor
          :editor="editor"
          v-model="form.header_template"
          @ready="onReady"
          :config="editorConfig"
        ></ckeditor>-->
        <Tinymce ref="editor" class="naw_Editor" v-model="content" :height="800" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import Tinymce from "@/components/Tinymce";

export default {
  mixins: [form],
  name: "createForm",
  components: {
    Tinymce
  },
  data() {
    return {
      // list:[],
      activeName: "2",
      serchoInput: "",
      save_loading: false,
      update_loading: false,
      remove_loading: false,
      template: "",
      form: {
        name: "",
        header_template: "",
        content: "",
        header_template: "",
        hide_header: false,
        hide_footer: false
      }
    };
  },
  computed: {
    ...mapGetters({
      list: "formTemplate/list",
      categories: "categoryDocumentation/list",
      model: "formTemplate/model"
    })
  },
  watch: {},
  created() {
    if (this.categories.length == 0) {
      this.categoryList();
    }
  },
  methods: {
    ...mapActions({
      updateList: "formTemplate/index",
      save: "formTemplate/store",
      update: "formTemplate/update",
      remove: "formTemplate/destroy",
      categoryList: "categoryDocumentation/index"
    }),
    saveData() {
      if (
        this.form.category_id != "" &&
        this.form.name != "" &&
        this.form.content != ""
      ) {
        this.save_loading = true;
        this.save(this.form)
          .then(res => {
            this.save_loading = false;
            this.resetForm("form");
            this.parent().listChanged();
            this.$notify({
              title: 'Успешно',
              type: "success",
              offset: 130,
              message: res.message
            });
            this.loadModel();
          })
          .catch(err => {
            this.save_loading = false;
            this.$notify({
              title: 'Ошибка',
              type: "error",
              offset: 130,
              message: err.error.message
            });
            console.log(err);
          });
      } else {
        this.$alert("Empty data");
      }
    },
    // updateData(){
    //     if (this.form.id) {
    //         this.update_loading = true;
    //         this.update(this.form).then(res => {
    //             // console.log(res);
    //             this.$notify({
    //   title: 'Успешно',
    //   type: "success",
    //   offset: 130,
    //   message: res.message
    // });
    //             this.update_loading = false;
    //             this.loadModel()
    //         }).catch(err => {
    //             this.update_loading = false;
    //             console.log(err);
    //         });
    //     }
    //     else{
    //         this.$alert({code:402,message:'Place change template form',success:false});
    //     }
    // },
    // removeData(){
    //     if (this.form.id) {
    //         this.remove_loading = true;
    //         this.remove(this.form.id).then(res => {
    //             // console.log(res);
    //             this.remove_loading = false;
    //             this.loadModel()
    //             this.$notify({
    //   title: 'Успешно',
    //   type: "success",
    //   offset: 130,
    //   message: res.message
    // });
    //         }).catch(err => {
    //             this.remove_loading = false;
    //             console.log(err);
    //         });
    //     }
    //     else{
    //         this.$alert({code:402,message:'Place change template form',success:false});
    //     }
    // },
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    print() {
      let header =
        this.form.hide_header === true
          ? this.form.header_template + " <br>"
          : " ";
      let footer =
        this.form.hide_footer === true
          ? "<br> " + this.form.footer_template
          : " ";
      let DATA = header + this.form.content + footer;
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(DATA);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    change(id) {
      let item = this.list.filter(item => {
        return item.id == id;
      });
      if (item.length) {
        this.form.id = item[0].id;
        this.form.name = item[0].name;
        this.form.hide_header = item[0].hide_header;
        this.form.hide_footer = item[0].hide_footer;
        this.form.header_template = item[0].header_template;
        this.form.content = item[0].content;
        this.form.footer_template = item[0].footer_template;
      } else {
        this.form = {
          name: "",
          header_template: "",
          content: "",
          header_template: "",
          hide_header: false,
          hide_footer: false
        };
      }
    },
    loadModel() {
      this.form = {
        name: "",
        header_template: "",
        content: "",
        header_template: "",
        hide_header: false,
        hide_footer: false
      };
      this.template = "";
    },
    resetForm(formName) {
      // this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: "drawerCreate" });
    }
  }
};
</script>
<style lang="scss">
// .documentation_content
//   > .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline,
// .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
//   height: 600px !important;
// }
// .content-table {
//   line-height: 20px;
// }
// .content-table ul,
// .content-table p {
//   line-height: 24px;
// }
// .content-table table {
//   width: 100%;
// }
// .documentation_content .el-card.is-always-shadow {
//   min-height: 700px;
// }
// .content-table tr,
// .content-table td {
//   border: 1px solid black;
// }
// .documentatBlock .max_buttom:hover {
//   text-decoration: none;
// }
// .TitleBocks {
//   font-size: 18px;
//   line-height: 20px;
//   span {
//     font-size: 14px;
//     color: #8e8e8e;
//   }
// }
// .documents_block {
//   min-height: 750px;
//   background-color: #fff;
//   margin-top: 20px;
//   border-radius: 4px;
//   overflow: hidden;

//   // div{padding: 15px 20px;}
//   .document_title {
//     background-color: #cecece6b;
//   }
//   .item-documents {
//     border-top: 1px solid #f2f2f2;
//     transition: background-color 0.5s;
//     cursor: pointer;
//     &:hover {
//       background-color: #cecece6b;
//     }
//   }
// }
/*****************Khan13 ___ print style***********************/
figure.table td,
figure.table tr {
  line-height: 24px;
  border: 1px solid #6f6f6f;
}

/***************** end Khan13 ___ print style***********************/
</style>